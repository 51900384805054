body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

.fob-content-body {
  margin: -6.5em 1em 0 1em; }

.swal2-container {
  z-index: 99999 !important; }

.fob-notes-container {
  background-color: #eeebe9;
  color: #000000;
  padding: 1.5em;
  border-radius: 1.125em;
  line-height: 1.5em;
  font-size: 0.85em;
  width: 100%;
  min-height: 5em; }

.rounded-btn, .common-loading-button {
  border-radius: 18px !important; }

.dark-btn {
  color: #ffffff !important;
  background-color: #776a61 !important;
  text-transform: capitalize !important; }

.dark-blue-btn {
  color: #ffffff !important;
  background-color: #212a3e !important;
  text-transform: capitalize !important; }
  .dark-blue-btn:disabled {
    color: #ffffff !important;
    background-color: #212a3e !important; }

.active-btn {
  color: #ffffff !important;
  background-color: #F51162 !important;
  text-transform: capitalize !important; }
  .active-btn:disabled {
    color: #ffffff !important;
    background-color: #BBBCBE !important; }

.rounded-input > .MuiInputBase-root {
  border-radius: 2em; }
  .rounded-input > .MuiInputBase-root.MuiInputBase-multiline {
    border-radius: 1.125em;
    padding: 1.5em;
    margin-top: 1em;
    margin-bottom: 1em; }
    .rounded-input > .MuiInputBase-root.MuiInputBase-multiline .MuiInputBase-inputMultiline {
      line-height: 1.5em;
      min-height: 5em;
      font-size: 0.85em; }
  .rounded-input > .MuiInputBase-root .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0; }

.fob-date-picker > .MuiInputBase-root .MuiSvgIcon-root {
  color: #A99D95; }

.fob-date-picker > .MuiInputBase-root:hover:before {
  border: none; }

.fob-date-picker > .MuiInputBase-root:before, .fob-date-picker > .MuiInputBase-root:after {
  border: none; }

.fob-filled-input-with-outside-label .MuiInputBase-root {
  background-color: #eeebe9; }
  .fob-filled-input-with-outside-label .MuiInputBase-root .MuiInputBase-input {
    padding-left: 12px; }

.fob-input-btn-form-control {
  flex-direction: column; }

.MuiFilledInput-underline.Mui-disabled:before {
  border-bottom-style: none !important; }

.fob-date-picker {
  min-width: 100%; }
  .fob-date-picker input {
    padding: 7px 12px; }

.form-label-with-icon {
  margin-top: -6px; }
  .form-label-with-icon .MuiSvgIcon-root {
    margin-bottom: -6px; }

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 13px; }

.fob-search-input-container .MuiFilledInput-adornedStart {
  padding-left: 8px; }

.fob-search-input-container .MuiFilledInput-root {
  border-radius: 2em;
  border: 1px solid #eeebe9; }
  .fob-search-input-container .MuiFilledInput-root .MuiInputAdornment-root {
    color: #A99D95; }
  .fob-search-input-container .MuiFilledInput-root > input {
    padding: 0.5em 0 0.5em 1em;
    color: #3f3833;
    border-radius: inherit; }
  .fob-search-input-container .MuiFilledInput-root::after {
    border-bottom: none; }

.fob-search-input-contrast .MuiFilledInput-root {
  border: 1px solid white;
  background-color: #ffffff; }
  .fob-search-input-contrast .MuiFilledInput-root:hover {
    background-color: #ffffff !important; }

.fob-search-input-contrast .MuiFilledInput-root.Mui-focused {
  background-color: #ffffff !important; }

.MuiTable-root.no-border .MuiTableCell-root {
  border-bottom: none;
  font-size: 1em; }
  .MuiTable-root.no-border .MuiTableCell-root:first-child {
    width: 30%; }
  .MuiTable-root.no-border .MuiTableCell-root:last-child {
    width: 60%; }

.MuiTable-root .selected-column .MuiTableSortLabel-icon {
  opacity: 0.4; }

.MuiTable-root .not-selected-column .MuiTableSortLabel-icon {
  opacity: 0 !important; }

.fob-details-dialog .MuiDialogTitle-root {
  padding: 16px 24px 8px 24px; }
  .fob-details-dialog .MuiDialogTitle-root .MuiToolbar-root {
    padding: 0; }

.fob-details-dialog .MuiDialogContent-root {
  padding: 16px 24px 8px 24px; }
  .fob-details-dialog .MuiDialogContent-root > .MuiGrid-container {
    align-items: flex-start; }
    .fob-details-dialog .MuiDialogContent-root > .MuiGrid-container > .MuiGrid-item:first-of-type {
      padding-right: 2em; }
  .fob-details-dialog .MuiDialogContent-root table {
    padding: 0 1em 1em; }
    .fob-details-dialog .MuiDialogContent-root table tr:first-of-type td {
      padding: 0 0.5em 0.5em 0; }
      .fob-details-dialog .MuiDialogContent-root table tr:first-of-type td:first-of-type {
        padding: 0 1.5em 0.5em 0; }
    .fob-details-dialog .MuiDialogContent-root table tr td {
      padding: 0.5em 0; }
      .fob-details-dialog .MuiDialogContent-root table tr td:first-of-type {
        padding-right: 1.5em; }
      .fob-details-dialog .MuiDialogContent-root table tr td:last-of-type {
        color: #A99D95; }
      .fob-details-dialog .MuiDialogContent-root table tr td .MuiFilledInput-input {
        padding: 10px 12px; }
        .fob-details-dialog .MuiDialogContent-root table tr td .MuiFilledInput-input.MuiInputBase-inputAdornedStart {
          padding-left: 0; }
        .fob-details-dialog .MuiDialogContent-root table tr td .MuiFilledInput-input.MuiInputBase-inputAdornedEnd {
          padding-right: 0; }
      .fob-details-dialog .MuiDialogContent-root table tr td .input-select {
        width: 100%; }

.fob-details-dialog .MuiDialogActions-root {
  justify-content: center;
  padding: 2em 0 2.5em 0; }
  .fob-details-dialog .MuiDialogActions-root .MuiButton-root {
    width: 70%;
    max-width: 180px;
    margin: 0 0.75em; }

.fob-form-dialog .MuiDialogContent-root {
  padding: 2em 2em 3em 2em; }

/* Mobile Styles */
.MuiDialog-root-mobile.fob-details-dialog .MuiDialogActions-root .MuiButton-root {
  width: 100%; }

.fob-dropzone {
  border-radius: 8px; }

.fob-upload-dialog > .MuiDialog-container > .MuiDialog-paper {
  max-width: 100%;
  min-width: 560px; }

.fob-upload-dialog .MuiDialogActions-root {
  justify-content: center;
  padding: 2em 0 2.5em 0; }
  .fob-upload-dialog .MuiDialogActions-root .MuiButton-root {
    width: 70%;
    max-width: 180px;
    margin: 0 0.75em; }

/* Mobile styles */
.MuiDialog-root-mobile.fob-upload-dialog > .MuiDialog-container > .MuiDialog-paper {
  max-width: 100%;
  min-width: 0; }

.fob-alert-container {
  margin: 0 8px 12px 8px; }

.fob-table-container > .MuiPaper-root header {
  box-shadow: none; }

.fob-table-container > .MuiPaper-root .MuiCardContent-root {
  padding: 0; }
  .fob-table-container > .MuiPaper-root .MuiCardContent-root .MuiTableContainer-root {
    box-shadow: none; }

.swal2-popup.swal2-toast {
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 0.625em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: none; }
  .swal2-popup.swal2-toast .swal2-header {
    flex-direction: row;
    padding: 0; }
  .swal2-popup.swal2-toast .swal2-title {
    flex-grow: 1;
    justify-content: flex-start;
    margin: 0 0.6em;
    font-size: 1em; }
  .swal2-popup.swal2-toast .swal2-footer {
    margin: 0.5em 0 0;
    padding: 0.5em 0 0;
    font-size: 0.8em; }
  .swal2-popup.swal2-toast .swal2-close {
    position: static;
    width: 0.8em;
    height: 0.8em;
    line-height: 0.8; }
  .swal2-popup.swal2-toast .swal2-content {
    justify-content: flex-start;
    padding: 0;
    font-size: 1em; }
  .swal2-popup.swal2-toast .swal2-icon {
    width: 2em;
    min-width: 2em;
    height: 2em;
    margin: 0; }
    .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
      display: flex;
      align-items: center;
      font-size: 1.8em;
      font-weight: bold; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
          font-size: .25em; } }
    .swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
      width: 2em;
      height: 2em; }
    .swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
      top: .875em;
      width: 1.375em; }
      .swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
        left: .3125em; }
      .swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
        right: .3125em; }
  .swal2-popup.swal2-toast .swal2-actions {
    flex-basis: auto !important;
    width: auto;
    height: auto;
    margin: 0 .3125em; }
  .swal2-popup.swal2-toast .swal2-styled {
    margin: 0 .3125em;
    padding: .3125em .625em;
    font-size: 1em; }
    .swal2-popup.swal2-toast .swal2-styled:focus {
      box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(50, 100, 150, 0.4); }
  .swal2-popup.swal2-toast .swal2-success {
    border-color: #a5dc86; }
    .swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'] {
      position: absolute;
      width: 1.6em;
      height: 3em;
      transform: rotate(45deg);
      border-radius: 50%; }
      .swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'][class$='left'] {
        top: -.8em;
        left: -.5em;
        transform: rotate(-45deg);
        transform-origin: 2em 2em;
        border-radius: 4em 0 0 4em; }
      .swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'][class$='right'] {
        top: -.25em;
        left: .9375em;
        transform-origin: 0 1.5em;
        border-radius: 0 4em 4em 0; }
    .swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
      width: 2em;
      height: 2em; }
    .swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
      top: 0;
      left: .4375em;
      width: .4375em;
      height: 2.6875em; }
    .swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'] {
      height: .3125em; }
      .swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'][class$='tip'] {
        top: 1.125em;
        left: .1875em;
        width: .75em; }
      .swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'][class$='long'] {
        top: .9375em;
        right: .1875em;
        width: 1.375em; }
  .swal2-popup.swal2-toast.swal2-show {
    animation: none; }
  .swal2-popup.swal2-toast.swal2-hide {
    animation: none; }

.swal2-container {
  display: flex;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: initial;
  -webkit-overflow-scrolling: touch; }
  .swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: rgba(0, 0, 0, 0.4); }
  .swal2-container.swal2-backdrop-hide {
    background: transparent !important; }
  .swal2-container.swal2-top {
    align-items: flex-start; }
  .swal2-container.swal2-top-start, .swal2-container.swal2-top-left {
    align-items: flex-start;
    justify-content: flex-start; }
  .swal2-container.swal2-top-end, .swal2-container.swal2-top-right {
    align-items: flex-start;
    justify-content: flex-end; }
  .swal2-container.swal2-center {
    align-items: center; }
  .swal2-container.swal2-center-start, .swal2-container.swal2-center-left {
    align-items: center;
    justify-content: flex-start; }
  .swal2-container.swal2-center-end, .swal2-container.swal2-center-right {
    align-items: center;
    justify-content: flex-end; }
  .swal2-container.swal2-bottom {
    align-items: flex-end; }
  .swal2-container.swal2-bottom-start, .swal2-container.swal2-bottom-left {
    align-items: flex-end;
    justify-content: flex-start; }
  .swal2-container.swal2-bottom-end, .swal2-container.swal2-bottom-right {
    align-items: flex-end;
    justify-content: flex-end; }
  .swal2-container.swal2-bottom > :first-child,
  .swal2-container.swal2-bottom-start > :first-child,
  .swal2-container.swal2-bottom-left > :first-child,
  .swal2-container.swal2-bottom-end > :first-child,
  .swal2-container.swal2-bottom-right > :first-child {
    margin-top: auto; }
  .swal2-container.swal2-grow-fullscreen > .swal2-modal {
    display: flex !important;
    flex: 1;
    align-self: stretch;
    justify-content: center; }
  .swal2-container.swal2-grow-row > .swal2-modal {
    display: flex !important;
    flex: 1;
    align-content: center;
    justify-content: center; }
  .swal2-container.swal2-grow-column {
    flex: 1;
    flex-direction: column; }
    .swal2-container.swal2-grow-column.swal2-top, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-bottom {
      align-items: center; }
    .swal2-container.swal2-grow-column.swal2-top-start, .swal2-container.swal2-grow-column.swal2-center-start, .swal2-container.swal2-grow-column.swal2-bottom-start, .swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-bottom-left {
      align-items: flex-start; }
    .swal2-container.swal2-grow-column.swal2-top-end, .swal2-container.swal2-grow-column.swal2-center-end, .swal2-container.swal2-grow-column.swal2-bottom-end, .swal2-container.swal2-grow-column.swal2-top-right, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-bottom-right {
      align-items: flex-end; }
    .swal2-container.swal2-grow-column > .swal2-modal {
      display: flex !important;
      flex: 1;
      align-content: center;
      justify-content: center; }
  .swal2-container.swal2-no-transition {
    transition: none !important; }
  .swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen) > .swal2-modal {
    margin: auto; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .swal2-container .swal2-modal {
      margin: 0 !important; } }

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 0;
  background: #fff;
  font-family: inherit;
  font-size: 1rem; }
  .swal2-popup:focus {
    outline: none; }
  .swal2-popup.swal2-loading {
    overflow-y: hidden; }

.swal2-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.8em; }

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word; }

.swal2-actions {
  display: flex;
  z-index: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1.25em auto 0; }
  .swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
    opacity: .4; }
  .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }
  .swal2-actions:not(.swal2-loading) .swal2-styled:active {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }
  .swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
    box-sizing: border-box;
    width: 2.5em;
    height: 2.5em;
    margin: .46875em;
    padding: 0;
    animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
    border: .25em solid transparent;
    border-radius: 100%;
    border-color: transparent;
    background-color: transparent !important;
    color: transparent !important;
    cursor: default;
    user-select: none; }
  .swal2-actions.swal2-loading .swal2-styled.swal2-cancel {
    margin-right: 30px;
    margin-left: 30px; }
  .swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
    border: 3px solid #999999;
    border-radius: 50%;
    border-right-color: transparent;
    box-shadow: 1px 1px 1px #fff; }

.swal2-styled {
  margin: .3125em;
  padding: .625em 2em;
  box-shadow: none;
  font-weight: 500; }
  .swal2-styled:not([disabled]) {
    cursor: pointer; }
  .swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0;
    background: initial;
    background-color: #3085d6;
    color: #fff;
    font-size: 1.0625em; }
  .swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 0;
    background: initial;
    background-color: #aaa;
    color: #fff;
    font-size: 1.0625em; }
  .swal2-styled:focus {
    outline: none;
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(50, 100, 150, 0.4); }
  .swal2-styled::-moz-focus-inner {
    border: 0; }

.swal2-footer {
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em; }

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.25em;
  overflow: hidden;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.swal2-timer-progress-bar {
  width: 100%;
  height: 0.25em;
  background: rgba(0, 0, 0, 0.2); }

.swal2-image {
  max-width: 100%;
  margin: 1.25em auto; }

.swal2-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  overflow: hidden;
  transition: initial;
  border: none;
  border-radius: 0;
  background: transparent;
  color: #cccccc;
  font-family: serif;
  font-size: 2.5em;
  line-height: 1.2;
  cursor: pointer; }
  .swal2-close:hover {
    transform: none;
    background: transparent;
    color: #f27474; }
  .swal2-close::-moz-focus-inner {
    border: 0; }

.swal2-content {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 0 1.6em;
  color: #545454;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word; }

.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 1em auto; }

.swal2-input,
.swal2-file,
.swal2-textarea {
  box-sizing: border-box;
  width: 100%;
  transition: initial;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  background: inherit;
  box-shadow: none;
  color: inherit;
  font-size: 1.125em; }
  .swal2-input.swal2-inputerror,
  .swal2-file.swal2-inputerror,
  .swal2-textarea.swal2-inputerror {
    border-color: #f27474 !important;
    box-shadow: 0 0 2px #f27474 !important; }
  .swal2-input:focus,
  .swal2-file:focus,
  .swal2-textarea:focus {
    border: 1px solid #b4dbed;
    outline: none;
    box-shadow: 0 0 3px #c4e6f5; }
  .swal2-input::placeholder,
  .swal2-file::placeholder,
  .swal2-textarea::placeholder {
    color: #cccccc; }

.swal2-range {
  margin: 1em auto;
  background: #fff; }
  .swal2-range input {
    width: 80%; }
  .swal2-range output {
    width: 20%;
    color: inherit;
    font-weight: 600;
    text-align: center; }
  .swal2-range input,
  .swal2-range output {
    height: 2.625em;
    padding: 0;
    font-size: 1.125em;
    line-height: 2.625em; }

.swal2-input {
  height: 2.625em;
  padding: 0 0.75em; }
  .swal2-input[type='number'] {
    max-width: 10em; }

.swal2-file {
  background: inherit;
  font-size: 1.125em; }

.swal2-textarea {
  height: 6.75em;
  padding: 0.75em; }

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em; }

.swal2-radio,
.swal2-checkbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit; }
  .swal2-radio label,
  .swal2-checkbox label {
    margin: 0 .6em;
    font-size: 1.125em; }
  .swal2-radio input,
  .swal2-checkbox input {
    margin: 0 .4em; }

.swal2-validation-message {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666666;
  font-size: 1em;
  font-weight: 300; }
  .swal2-validation-message::before {
    content: '!';
    display: inline-block;
    width: 1.5em;
    min-width: 1.5em;
    height: 1.5em;
    margin: 0 .625em;
    border-radius: 50%;
    background-color: #f27474;
    color: #fff;
    font-weight: 600;
    line-height: 1.5em;
    text-align: center; }

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: .25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none; }
  .swal2-icon .swal2-icon-content {
    display: flex;
    align-items: center;
    font-size: 3.75em; }
  .swal2-icon.swal2-error {
    border-color: #f27474;
    color: #f27474; }
    .swal2-icon.swal2-error .swal2-x-mark {
      position: relative;
      flex-grow: 1; }
    .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
      display: block;
      position: absolute;
      top: 2.3125em;
      width: 2.9375em;
      height: .3125em;
      border-radius: .125em;
      background-color: #f27474; }
      .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
        left: 1.0625em;
        transform: rotate(45deg); }
      .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
        right: 1em;
        transform: rotate(-45deg); }
  .swal2-icon.swal2-warning {
    border-color: #facea8;
    color: #f8bb86; }
  .swal2-icon.swal2-info {
    border-color: #9de0f6;
    color: #3fc3ee; }
  .swal2-icon.swal2-question {
    border-color: #c9dae1;
    color: #87adbd; }
  .swal2-icon.swal2-success {
    border-color: #a5dc86;
    color: #a5dc86; }
    .swal2-icon.swal2-success [class^='swal2-success-circular-line'] {
      position: absolute;
      width: 3.75em;
      height: 7.5em;
      transform: rotate(45deg);
      border-radius: 50%; }
      .swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='left'] {
        top: -.4375em;
        left: -2.0635em;
        transform: rotate(-45deg);
        transform-origin: 3.75em 3.75em;
        border-radius: 7.5em 0 0 7.5em; }
      .swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='right'] {
        top: -.6875em;
        left: 1.875em;
        transform: rotate(-45deg);
        transform-origin: 0 3.75em;
        border-radius: 0 7.5em 7.5em 0; }
    .swal2-icon.swal2-success .swal2-success-ring {
      position: absolute;
      z-index: 2;
      top: -.25em;
      left: -.25em;
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      border: 0.25em solid rgba(165, 220, 134, 0.3);
      border-radius: 50%; }
    .swal2-icon.swal2-success .swal2-success-fix {
      position: absolute;
      z-index: 1;
      top: .5em;
      left: 1.625em;
      width: .4375em;
      height: 5.625em;
      transform: rotate(-45deg); }
    .swal2-icon.swal2-success [class^='swal2-success-line'] {
      display: block;
      position: absolute;
      z-index: 2;
      height: .3125em;
      border-radius: .125em;
      background-color: #a5dc86; }
      .swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
        top: 2.875em;
        left: .8125em;
        width: 1.5625em;
        transform: rotate(45deg); }
      .swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
        top: 2.375em;
        right: .5em;
        width: 2.9375em;
        transform: rotate(-45deg); }

.swal2-progress-steps {
  align-items: center;
  margin: 0 0 1.25em;
  padding: 0;
  background: inherit;
  font-weight: 600; }
  .swal2-progress-steps li {
    display: inline-block;
    position: relative; }
  .swal2-progress-steps .swal2-progress-step {
    z-index: 20;
    width: 2em;
    height: 2em;
    border-radius: 2em;
    background: #3085d6;
    color: #fff;
    line-height: 2em;
    text-align: center; }
    .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
      background: #3085d6; }
      .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
        background: #add8e6;
        color: #fff; }
      .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
        background: #add8e6; }
  .swal2-progress-steps .swal2-progress-step-line {
    z-index: 10;
    width: 2.5em;
    height: .4em;
    margin: 0 -1px;
    background: #3085d6; }

[class^='swal2'] {
  -webkit-tap-highlight-color: transparent; }

.swal2-show {
  animation: none; }

.swal2-hide {
  animation: none; }

.swal2-noanimation {
  transition: none; }

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.swal2-rtl .swal2-close {
  right: auto;
  left: 0; }

.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto; }

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important; }
  .swal2-range output {
    display: none; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important; }
  .swal2-range output {
    display: none; } }

@-moz-document url-prefix() {
  .swal2-close:focus {
    outline: 2px solid rgba(50, 100, 150, 0.4); } }

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0deg); } }

@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0deg);
    opacity: 1; } }

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden; }

body.swal2-height-auto {
  height: auto !important; }

body.swal2-no-backdrop .swal2-container {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  max-width: calc(100% - 0.625em * 2);
  background-color: transparent !important; }
  body.swal2-no-backdrop .swal2-container > .swal2-modal {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  body.swal2-no-backdrop .swal2-container.swal2-top {
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  body.swal2-no-backdrop .swal2-container.swal2-top-start, body.swal2-no-backdrop .swal2-container.swal2-top-left {
    top: 0;
    left: 0; }
  body.swal2-no-backdrop .swal2-container.swal2-top-end, body.swal2-no-backdrop .swal2-container.swal2-top-right {
    top: 0;
    right: 0; }
  body.swal2-no-backdrop .swal2-container.swal2-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  body.swal2-no-backdrop .swal2-container.swal2-center-start, body.swal2-no-backdrop .swal2-container.swal2-center-left {
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  body.swal2-no-backdrop .swal2-container.swal2-center-end, body.swal2-no-backdrop .swal2-container.swal2-center-right {
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
  body.swal2-no-backdrop .swal2-container.swal2-bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  body.swal2-no-backdrop .swal2-container.swal2-bottom-start, body.swal2-no-backdrop .swal2-container.swal2-bottom-left {
    bottom: 0;
    left: 0; }
  body.swal2-no-backdrop .swal2-container.swal2-bottom-end, body.swal2-no-backdrop .swal2-container.swal2-bottom-right {
    right: 0;
    bottom: 0; }

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important; }
    body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden='true'] {
      display: none; }
    body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
      position: static !important; } }

body.swal2-toast-shown .swal2-container {
  background-color: transparent; }
  body.swal2-toast-shown .swal2-container.swal2-top {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%); }
  body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto; }
  body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
    top: 0;
    right: auto;
    bottom: auto;
    left: 0; }
  body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 0;
    transform: translateY(-50%); }
  body.swal2-toast-shown .swal2-container.swal2-center {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%); }
  body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }
  body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0; }
  body.swal2-toast-shown .swal2-container.swal2-bottom {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto; }

body.swal2-toast-column .swal2-toast {
  flex-direction: column;
  align-items: stretch; }
  body.swal2-toast-column .swal2-toast .swal2-actions {
    flex: 1;
    align-self: stretch;
    height: 2.2em;
    margin-top: .3125em; }
  body.swal2-toast-column .swal2-toast .swal2-loading {
    justify-content: center; }
  body.swal2-toast-column .swal2-toast .swal2-input {
    height: 2em;
    margin: .3125em auto;
    font-size: 1em; }
  body.swal2-toast-column .swal2-toast .swal2-validation-message {
    font-size: 1em; }

.main-content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto; }
  .main-content-wrapper > div {
    width: 100%; }

.home-welcome-page {
  text-align: center; }
  .home-welcome-page .app {
    text-align: center; }
  .home-welcome-page .rekit-logo {
    animation: app-logo-spin infinite 10s linear;
    height: 80px; }
  .home-welcome-page .app-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
    overflow: hidden;
    position: relative; }
  .home-welcome-page .app-title {
    font-size: 1.5em; }
  .home-welcome-page .app-intro {
    font-size: large; }
  .home-welcome-page ul,
  .home-welcome-page li {
    list-style: none;
    margin: 0;
    padding: 0; }
  .home-welcome-page ul {
    margin-top: 20px; }
  .home-welcome-page li {
    margin-top: 10px; }
  .home-welcome-page a {
    color: #0288d1;
    text-decoration: none; }
    .home-welcome-page a:hover {
      text-decoration: underline; }
  .home-welcome-page p.memo {
    width: 500px;
    color: #999;
    font-size: 12px;
    line-height: 150%;
    margin: auto; }

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.common-top-nav.common-top-nav-mobile {
  position: fixed;
  width: 100%;
  z-index: 1; }
  .common-top-nav.common-top-nav-mobile .common-top-nav-user-space {
    padding: 15px 0em 15px 1em; }
  .common-top-nav.common-top-nav-mobile header .MuiToolbar-regular {
    justify-content: space-between; }

.common-top-nav header > .MuiToolbar-root {
  background-color: white; }

.common-top-nav header .MuiToolbar-regular {
  max-height: 64px; }

.common-top-nav header .common-top-nav-company-icon {
  height: 32px; }

.common-top-nav .common-top-nav-user-space {
  display: flex;
  flex-direction: row;
  padding: 15px 2.5em 10px; }
  .common-top-nav .common-top-nav-user-space .common-top-nav-user-space-labels {
    display: inline-flex;
    flex-direction: column;
    padding: 0 8px 0; }
    .common-top-nav .common-top-nav-user-space .common-top-nav-user-space-labels p {
      margin: 0;
      font-weight: 600;
      font-size: 0.8em; }
      .common-top-nav .common-top-nav-user-space .common-top-nav-user-space-labels p:last-of-type {
        color: #E3475E;
        margin: 3px 0 0; }
  .common-top-nav .common-top-nav-user-space .MuiAvatar-root {
    display: inline-flex; }
    .common-top-nav .common-top-nav-user-space .MuiAvatar-root img {
      max-height: 30px;
      max-width: 30px;
      border-radius: 24px; }

.common-top-nav .common-top-nav-notifications-btn {
  background-color: #e2dedb;
  padding: 5px;
  margin: 8px 16px; }
  .common-top-nav .common-top-nav-notifications-btn .MuiIconButton-label svg {
    width: 0.85em;
    height: 0.85em; }
  .common-top-nav .common-top-nav-notifications-btn .MuiIconButton-label .MuiBadge-badge {
    transform: scale(1) translate(-25%, 25%); }

.common-side-nav.common-side-nav-mobile .drawer-closed > .MuiDrawer-paper {
  overflow: hidden !important; }

.common-side-nav.common-side-nav-mobile > .MuiDrawer-root .primary-menu-bar-list-item {
  padding-bottom: 2.5rem; }
  .common-side-nav.common-side-nav-mobile > .MuiDrawer-root .primary-menu-bar-list-item .primary-menu-bar {
    position: fixed;
    left: 0;
    background-color: white;
    border-radius: 0 10px 10px 0;
    padding: 3px 10px;
    z-index: 1; }

.common-side-nav.common-side-nav-mobile > .MuiDrawer-root .sidepanelClosed .primary-menu-bar-list-item .primary-menu-bar {
  padding: 3px 24px; }

.common-side-nav > .MuiDrawer-root > .MuiDrawer-paper {
  top: auto; }
  .common-side-nav > .MuiDrawer-root > .MuiDrawer-paper .MuiList-root {
    padding: 0 0.75em 1.5em; }
    .common-side-nav > .MuiDrawer-root > .MuiDrawer-paper .MuiList-root > .MuiListItem-button {
      margin: 0.5em 0;
      background-color: white;
      border-radius: 6px;
      height: 46px; }
      .common-side-nav > .MuiDrawer-root > .MuiDrawer-paper .MuiList-root > .MuiListItem-button .MuiListItemText-primary {
        font-size: 0.80em;
        font-weight: 600; }
    .common-side-nav > .MuiDrawer-root > .MuiDrawer-paper .MuiList-root > .MuiListItem-button.selected {
      background-color: #E3475E;
      color: white; }
      .common-side-nav > .MuiDrawer-root > .MuiDrawer-paper .MuiList-root > .MuiListItem-button.selected svg {
        color: white; }

.common-side-nav > .MuiDrawer-root .primary-menu-bar-list-item {
  padding-top: 0;
  margin-left: -2px; }
  .common-side-nav > .MuiDrawer-root .primary-menu-bar-list-item .primary-menu-bar {
    padding: 0; }
    .common-side-nav > .MuiDrawer-root .primary-menu-bar-list-item .primary-menu-bar .MuiSvgIcon-root {
      font-size: 2rem; }

.common-side-nav .drawer-closed > .MuiDrawer-paper {
  top: auto; }
  .common-side-nav .drawer-closed > .MuiDrawer-paper .MuiList-root {
    padding: 0 0.5em 1.5em; }
    .common-side-nav .drawer-closed > .MuiDrawer-paper .MuiList-root .MuiListItem-button .MuiListItemIcon-root {
      padding-left: 4px; }

.common-side-nav .drawer-closed .primary-menu-bar-list-item {
  margin-left: 2px; }

.common-page-header {
  display: flex;
  flex: 1;
  height: 150px;
  background-color: #E3475E;
  padding: 1.5em;
  color: #ffffff; }
  .common-page-header .MuiBreadcrumbs-root {
    color: inherit; }
    .common-page-header .MuiBreadcrumbs-root li {
      color: inherit;
      font-size: 0.6em;
      text-transform: uppercase;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9); }
      .common-page-header .MuiBreadcrumbs-root li p {
        color: inherit;
        font-size: inherit;
        text-transform: inherit;
        font-weight: 600; }

/* Mobile styles */
.main-content-wrapper-mobile .common-page-header {
  padding-top: 3em;
  height: 135px; }

.common-copyright {
  margin: 2em 0 1.5em 0;
  text-align: center; }
  .common-copyright p {
    font-size: 1em;
    color: #A99D95; }

.common-base-table .common-base-table-header {
  padding: 0.5em 0; }
  .common-base-table .common-base-table-header .MuiDivider-vertical {
    margin: 0 1.25em; }
  .common-base-table .common-base-table-header .user-profile-space p {
    font-size: 0.80rem;
    font-weight: 600; }
  .common-base-table .common-base-table-header table tbody tr td:first-of-type {
    padding-right: 1em; }

.common-base-table .common-base-table-content .MuiTableCell-head {
  font-weight: 600; }

.common-base-table .result-row-info h3 {
  margin: 0;
  color: #E3475E; }

/* Mobile styles */
.main-content-wrapper-mobile .common-base-table .MuiTablePagination-root {
  width: 100%; }
  .main-content-wrapper-mobile .common-base-table .MuiTablePagination-root .MuiTablePagination-toolbar {
    display: block;
    padding-left: 8px;
    padding-right: 8px; }
    .main-content-wrapper-mobile .common-base-table .MuiTablePagination-root .MuiTablePagination-toolbar > .MuiTablePagination-caption {
      float: left;
      line-height: 2; }
      .main-content-wrapper-mobile .common-base-table .MuiTablePagination-root .MuiTablePagination-toolbar > .MuiTablePagination-caption:nth-child(4) {
        float: right; }
    .main-content-wrapper-mobile .common-base-table .MuiTablePagination-root .MuiTablePagination-toolbar > .MuiTablePagination-input {
      float: left; }
    .main-content-wrapper-mobile .common-base-table .MuiTablePagination-root .MuiTablePagination-toolbar > .MuiTablePagination-actions {
      display: flex;
      justify-content: center;
      clear: both;
      margin: 0; }

.common-common-data-table .datatable-add-item-btn {
  margin: 0 0 0 60px; }

.common-upload-box {
  width: 100%;
  border: dashed;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  min-height: 250px;
  border-color: #C8C8C8;
  background-color: #F0F0F0;
  border-radius: 8px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center; }
  .common-upload-box .common-upload-box-text-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center; }
    .common-upload-box .common-upload-box-text-container img {
      flex: auto;
      height: auto;
      max-width: 85px;
      margin: 2.5em 0 1.5em 0; }

.common-upload-box-files ul {
  padding: 0 16px !important;
  list-style-type: none !important; }
  .common-upload-box-files ul li {
    position: relative; }

.common-upload-box-files .common-upload-box-file-item-progress-container {
  display: inline-block;
  position: absolute;
  top: 20px;
  left: auto;
  margin-left: 20px;
  width: 100%;
  max-width: 390px; }
  .common-upload-box-files .common-upload-box-file-item-progress-container .common-upload-box-file-item-progress-section {
    padding: 8px 0; }
    .common-upload-box-files .common-upload-box-file-item-progress-container .common-upload-box-file-item-progress-section .uploaded-percentage {
      float: right;
      color: #E3475E; }
    .common-upload-box-files .common-upload-box-file-item-progress-container .common-upload-box-file-item-progress-section .uploaded-mbs {
      color: #aaa; }

.common-loading-button {
  min-width: 130px !important; }
  .common-loading-button .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important; }

.common-add-address-button {
  min-width: 170px !important; }

.common-my-select .MuiInputBase-root {
  padding-left: 8px; }

.common-my-select-without-label {
  margin-top: 15px; }

.common-toggle-group-daily-monthly {
  padding: 6px;
  background-color: #f5f5f5 !important;
  border-radius: 8px !important; }
  .common-toggle-group-daily-monthly .MuiToggleButton-root {
    background-color: transparent;
    border: none;
    color: #8e98a2;
    min-width: 130px;
    height: 38px; }
    .common-toggle-group-daily-monthly .MuiToggleButton-root .MuiToggleButton-label {
      text-transform: capitalize; }
  .common-toggle-group-daily-monthly .MuiToggleButton-root.Mui-selected {
    background-color: #fff;
    color: #6c54fc;
    border-radius: 6px;
    box-shadow: 0px 2px 1px -2px rgba(142, 152, 162, 0.95); }

.common-my-date-range-picker {
  display: flex;
  flex: 1; }
  .common-my-date-range-picker .common-my-date-range-picker-input-container {
    display: flex;
    flex: 1; }
    .common-my-date-range-picker .common-my-date-range-picker-input-container .MuiIconButton-root {
      padding: 6px 12px; }

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; }
  .loading-overlay.qr-code-loading {
    position: static;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.2); }
  .loading-overlay.page-content-loading {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0; }

.authentication-authentication-index {
  height: inherit;
  background: url("./../images/BackgroundRed@2x.png") no-repeat center center fixed;
  background-size: cover;
  overflow: hidden; }
  .authentication-authentication-index .authentication-grid-item {
    max-height: 80%;
    max-width: 80%;
    min-height: 580px; }
  .authentication-authentication-index .authentication-content {
    display: flex;
    flex: 1;
    background: url("./../images/auth_intro_bg.png") repeat-y center center;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    border-radius: 1em; }
    .authentication-authentication-index .authentication-content .authentication-content-right {
      padding: 1em 4vw;
      margin-left: -10px;
      background-color: white; }
      .authentication-authentication-index .authentication-content .authentication-content-right .authentication-header-content {
        margin: 0 0 1em 0; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-header-content.authentication-header-content-xs {
          margin-bottom: 0.25em; }
      .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container {
        position: relative; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form > .MuiGrid-root .authentication-form-grid-row .authentication-form-grid-cell {
          padding: 8px 0; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form > .MuiGrid-root .authentication-form-grid-row.error-xs-style {
          position: fixed;
          top: 0;
          align-items: center;
          justify-content: center; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form > .MuiGrid-root .authentication-form-grid-multiple-cells-row .authentication-form-grid-cell:first-of-type {
          padding-right: 8px; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form > .MuiGrid-root .authentication-form-grid-multiple-cells-row .authentication-form-grid-cell:not(:first-of-type) {
          padding-left: 8px; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form > .MuiGrid-root .authentication-form-grid-multiple-cells-row-xs .authentication-form-grid-cell:first-of-type, .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form > .MuiGrid-root .authentication-form-grid-multiple-cells-row-xs .authentication-form-grid-cell:not(:first-of-type) {
          padding-right: 8px;
          padding-left: 8px; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form .authentication-form-main-btn {
          border-radius: 1em;
          text-transform: capitalize;
          margin: 1.5em 0 1em 0; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form .authentication-form-question-opt {
          color: #A99D95;
          font-size: 0.85em;
          padding: 1em 0 0 0; }
          .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form .authentication-form-question-opt a {
            color: #E3475E;
            text-decoration: none; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-form-container > form .authentication-forgot-password {
          color: #E3475E;
          text-decoration: none; }
      .authentication-authentication-index .authentication-content .authentication-content-right .authentication-btn-group {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        margin: 0 0 8vh 0; }
        .authentication-authentication-index .authentication-content .authentication-content-right .authentication-btn-group .MuiToggleButtonGroup-root {
          border-radius: 2em;
          background: none; }
          .authentication-authentication-index .authentication-content .authentication-content-right .authentication-btn-group .MuiToggleButtonGroup-root button {
            background-color: #eeebe9;
            text-transform: capitalize;
            padding: 0 1em;
            width: 100px;
            color: #222222;
            border: none; }
            .authentication-authentication-index .authentication-content .authentication-content-right .authentication-btn-group .MuiToggleButtonGroup-root button:first-of-type {
              border-top-left-radius: 2em;
              border-bottom-left-radius: 2em; }
            .authentication-authentication-index .authentication-content .authentication-content-right .authentication-btn-group .MuiToggleButtonGroup-root button:last-of-type {
              border-top-right-radius: 2em;
              border-bottom-right-radius: 2em; }
          .authentication-authentication-index .authentication-content .authentication-content-right .authentication-btn-group .MuiToggleButtonGroup-root button.Mui-selected {
            background-color: #E3475E;
            color: #ffffff; }
  .authentication-authentication-index .authentication-copyright {
    color: #ffffff;
    text-align: center;
    padding: 6vh 0 1em 0; }
  .authentication-authentication-index .authentication-grid-item.MuiGrid-grid-xs-12 .authentication-content .authentication-content-right {
    margin: 0; }
    .authentication-authentication-index .authentication-grid-item.MuiGrid-grid-xs-12 .authentication-content .authentication-content-right .authentication-btn-group {
      margin: 0 0 2em 0; }
      .authentication-authentication-index .authentication-grid-item.MuiGrid-grid-xs-12 .authentication-content .authentication-content-right .authentication-btn-group.authentication-btn-group-xs {
        margin-bottom: 1em; }
  .authentication-authentication-index .authentication-grid-item.MuiGrid-grid-xs-12 .authentication-copyright {
    padding: 1em 0 0 0;
    font-size: 0.8em; }

.authentication-auth-text-field .MuiInputBase-input {
  color: #5b514a; }

.authentication-auth-text-field .MuiFormLabel-root, .authentication-auth-text-field .fob-input-btn-form-control label, .fob-input-btn-form-control .authentication-auth-text-field label {
  color: #A99D95;
  font-size: 0.85em; }

.authentication-auth-text-field .MuiFormLabel-root.Mui-focused, .authentication-auth-text-field .fob-input-btn-form-control label.Mui-focused, .fob-input-btn-form-control .authentication-auth-text-field label.Mui-focused {
  color: #A99D95; }

.authentication-auth-text-field .MuiInput-underline:hover:before {
  border-bottom: 2px solid rgba(169, 157, 149, 0.5) !important; }

.authentication-auth-text-field .MuiInput-underline:before {
  border-bottom: 1px solid rgba(169, 157, 149, 0.5); }

.authentication-auth-text-field .MuiInput-underline:after {
  border-bottom: 2px solid rgba(169, 157, 149, 0.5); }

.authentication-biometric-usage-prompt-dialog {
  height: 40%;
  bottom: -21px !important;
  top: auto !important; }
  .authentication-biometric-usage-prompt-dialog .MuiDialog-container .MuiDialogTitle-root {
    padding: 0;
    text-align: center;
    z-index: 1; }
    .authentication-biometric-usage-prompt-dialog .MuiDialog-container .MuiDialogTitle-root .top-icon {
      border-radius: 50%;
      background-color: red;
      color: white;
      padding: 8px;
      margin-bottom: -32px; }
  .authentication-biometric-usage-prompt-dialog .MuiDialog-container > .MuiDialog-paper {
    margin: 0;
    max-height: 100%;
    background-color: transparent;
    box-shadow: none;
    position: absolute;
    bottom: 20px; }
    .authentication-biometric-usage-prompt-dialog .MuiDialog-container > .MuiDialog-paper .MuiDialogContent-root {
      padding-top: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      background-color: #fff; }
      .authentication-biometric-usage-prompt-dialog .MuiDialog-container > .MuiDialog-paper .MuiDialogContent-root h3 {
        margin-bottom: 0;
        margin-top: 0.5rem;
        font-size: 1rem; }
      .authentication-biometric-usage-prompt-dialog .MuiDialog-container > .MuiDialog-paper .MuiDialogContent-root p {
        font-size: 0.75rem; }
        .authentication-biometric-usage-prompt-dialog .MuiDialog-container > .MuiDialog-paper .MuiDialogContent-root p:first-of-type {
          margin-bottom: 0;
          margin-top: 0.5rem; }
        .authentication-biometric-usage-prompt-dialog .MuiDialog-container > .MuiDialog-paper .MuiDialogContent-root p:last-of-type {
          margin: 0.5rem 0 0 0; }
  .authentication-biometric-usage-prompt-dialog .MuiDialog-container .MuiDialogActions-root {
    padding: 0; }
    .authentication-biometric-usage-prompt-dialog .MuiDialog-container .MuiDialogActions-root button {
      flex: 1;
      margin: 0;
      border-radius: 0; }

.equipments-my-equipment .fob-table-container {
  margin: -6.5em 1em 0 1em; }

.equipments-equipment-table .common-base-table-header .fob-search-input-contrast {
  max-width: 40%; }
  .equipments-equipment-table .common-base-table-header .fob-search-input-contrast .MuiFilledInput-root {
    padding: 0;
    box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.12); }

.equipments-equipment-table .common-base-table-header .header-actions {
  display: flex;
  flex: 1;
  justify-content: flex-end; }

.equipments-equipment-details-header .info-icon {
  width: 18px;
  height: auto; }

.equipments-equipment-details-header .equipments-equipment-details-header-grid-item {
  min-height: 200px; }
  .equipments-equipment-details-header .equipments-equipment-details-header-grid-item:nth-of-type(2) {
    padding: 0 8px;
    border-right: 1px inset rgba(0, 0, 0, 0.1); }
  .equipments-equipment-details-header .equipments-equipment-details-header-grid-item:nth-of-type(3) {
    padding: 0 8px; }
  .equipments-equipment-details-header .equipments-equipment-details-header-grid-item .equipments-equipment-details-header-td-btn-right {
    float: right;
    padding-right: 8px; }

.equipments-equipment-details-header .user-profile-space-container {
  border-right: 1px inset rgba(0, 0, 0, 0.1); }

.equipments-equipment-details-header header > .MuiToolbar-root {
  padding: 1em 2em;
  align-items: flex-start; }
  .equipments-equipment-details-header header > .MuiToolbar-root .MuiDivider-vertical {
    margin: 2em 1.25em; }
  .equipments-equipment-details-header header > .MuiToolbar-root table {
    width: 100% !important;
    table-layout: fixed; }
    .equipments-equipment-details-header header > .MuiToolbar-root table tr td {
      padding: 0.25em 0; }
    .equipments-equipment-details-header header > .MuiToolbar-root table tr td:nth-of-type(2) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .equipments-equipment-details-header header > .MuiToolbar-root .header-actions {
    margin: -100px 0 8px 0;
    justify-content: center;
    display: flex;
    flex-direction: column; }
    .equipments-equipment-details-header header > .MuiToolbar-root .header-actions .MuiButton-root {
      margin: 8px 0;
      width: 80%;
      max-width: 220px;
      min-width: 220px;
      padding: 0.5em 1em;
      justify-content: flex-start; }

.equipments-equipment-details-header .equipments-equipment-details-header-date-field {
  display: flex;
  flex-direction: row; }
  .equipments-equipment-details-header .equipments-equipment-details-header-date-field .fob-date-picker {
    margin-top: 0;
    margin-bottom: 0; }
    .equipments-equipment-details-header .equipments-equipment-details-header-date-field .fob-date-picker .MuiInputBase-formControl {
      padding-right: 0; }
      .equipments-equipment-details-header .equipments-equipment-details-header-date-field .fob-date-picker .MuiInputBase-formControl .MuiInputBase-input {
        padding-right: 0; }
      .equipments-equipment-details-header .equipments-equipment-details-header-date-field .fob-date-picker .MuiInputBase-formControl .MuiInputAdornment-positionEnd .MuiIconButton-root {
        padding: 0; }

.equipments-equipment-details-header .equipments-equipment-details-header-owner-field {
  display: flex;
  flex-direction: row;
  width: calc(100% - 36px); }

.equipments-equipment-details-header .equipments-equipment-details-header-serial-field {
  display: flex;
  flex-direction: row;
  width: calc(100% - 36px); }

/* Mobile styles */
.main-content-wrapper-mobile .equipments-equipment-details-header .equipments-equipment-details-header-grid-item {
  min-height: 70px; }

.main-content-wrapper-mobile .equipments-equipment-details-header header > .MuiToolbar-root .header-actions {
  margin: 8px 0 8px 0; }

.equipments-warranty-details-dialog .MuiDialog-container > .MuiDialog-paper {
  max-width: 700px;
  background-image: url("./../images/EditPaper@3x.png");
  background-repeat: no-repeat;
  background-position: 10% 85%;
  background-attachment: initial;
  background-size: 35%; }

.equipments-service-agreement-dialog .MuiDialog-container > .MuiDialog-paper {
  max-width: 700px;
  background-image: url("./../images/Unavailable@3x.png");
  background-repeat: no-repeat;
  background-position: 10% 85%;
  background-attachment: initial;
  background-size: 35%; }

.activity-image-dialog > .MuiDialog-container > .MuiDialog-paper {
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden; }

.activity-image-dialog .MuiDialogContent-root {
  display: flex;
  flex: 1;
  padding: 0; }
  .activity-image-dialog .MuiDialogContent-root > div > div {
    top: calc(50% + 40px) !important;
    height: calc(100% - 84px) !important;
    width: calc(100% - 64px) !important; }

.activity-media-container {
  width: 100%;
  height: 100%; }
  .activity-media-container .MuiCardContent-root {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center; }
    .activity-media-container .MuiCardContent-root img {
      max-width: 100%;
      max-height: 100%;
      justify-self: center;
      align-self: center; }
    .activity-media-container .MuiCardContent-root > div {
      width: 100% !important;
      height: 100% !important; }

.equipments-activity-details-dialog .MuiDialog-container > .MuiDialog-paper {
  max-width: 800px; }

.equipments-activity-details-dialog-content table {
  height: 100%;
  width: 100%;
  table-layout: fixed; }

.equipments-activity-details-dialog-content > .MuiGrid-root .activity-img-wrapper {
  position: relative; }
  .equipments-activity-details-dialog-content > .MuiGrid-root .activity-img-wrapper .activity-img {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: #ffffff; }
    .equipments-activity-details-dialog-content > .MuiGrid-root .activity-img-wrapper .activity-img img {
      width: 100%;
      height: auto;
      font-size: 2.5em; }

.equipments-activity-details-dialog-content .activity-media-carousel-container {
  width: 100%;
  height: 280px; }
  .equipments-activity-details-dialog-content .activity-media-carousel-container > div {
    position: relative; }

.equipments-activity-details-dialog-content .activity-card-file-preview {
  height: 250px;
  width: auto;
  text-align: center; }
  .equipments-activity-details-dialog-content .activity-card-file-preview .MuiCardMedia-root {
    height: 200px;
    width: 180px;
    padding: 8px;
    background-size: contain; }
  .equipments-activity-details-dialog-content .activity-card-file-preview .MuiCardContent-root {
    padding: 8px 0;
    background-color: #E3475E; }
    .equipments-activity-details-dialog-content .activity-card-file-preview .MuiCardContent-root a {
      text-decoration: none;
      color: #eee;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 11px; }

.equipments-activity-details-dialog-content .activity-preview-files {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .equipments-activity-details-dialog-content .activity-preview-files li {
    max-height: 100px !important; }
    .equipments-activity-details-dialog-content .activity-preview-files li img {
      max-width: 80px;
      max-height: 80px;
      height: auto; }

.react-player__preview {
  background-color: #3f3833; }

.equipments-activity-details-add-dialog-content table {
  height: 100%;
  width: 100%;
  table-layout: fixed; }

.equipments-activity-details-add-dialog-content .activity-img-wrapper {
  position: relative; }
  .equipments-activity-details-add-dialog-content .activity-img-wrapper .activity-img-input {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0; }
  .equipments-activity-details-add-dialog-content .activity-img-wrapper .activity-img {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: #ffffff; }
    .equipments-activity-details-add-dialog-content .activity-img-wrapper .activity-img .avatar-loading {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .equipments-activity-details-add-dialog-content .activity-img-wrapper .activity-img img {
      width: 100%;
      height: auto;
      font-size: 2.5em; }
  .equipments-activity-details-add-dialog-content .activity-img-wrapper .activity-img-edit-btn {
    position: absolute;
    right: -1em;
    top: -1em;
    opacity: 0;
    transition: opacity 0.2s; }
  .equipments-activity-details-add-dialog-content .activity-img-wrapper:hover .activity-img-edit-btn {
    opacity: 1; }

.equipments-add-equipment .equipments-add-equipment-body > .MuiGrid-root {
  padding: 2.5em 1em 1.5em 1em; }
  .equipments-add-equipment .equipments-add-equipment-body > .MuiGrid-root > .MuiGrid-item {
    padding: 1em; }

.equipments-add-equipment .MuiButton-root {
  justify-content: start; }

.equipments-add-equipment .equipments-add-equipment-warranty-service-section .form-control-btn-group:first-of-type {
  padding: 0 0 1em 0; }

.equipments-add-equipment .equipments-add-equipment-warranty-service-section .form-control-btn-group:last-of-type {
  padding: 1em 0 0 0; }

.equipments-add-equipment .equipments-add-equipment-warranty-service-section .form-control-btn-group label {
  padding: 0 0 1em 0.5em; }

.equipments-add-equipment .equipments-add-equipment-warranty-service-section .form-control-btn-group .MuiButton-root {
  width: 100%; }

.equipments-add-equipment .fob-user-details-container {
  min-height: 100px;
  border-right: 1px solid #eeebe9; }

.equipments-add-equipment .equipments-add-equipment-action-btns {
  padding: 2em 2em 0 2em; }
  .equipments-add-equipment .equipments-add-equipment-action-btns .MuiButton-root {
    justify-content: center; }

/* Mobile Styles */
.main-content-wrapper-mobile .equipments-add-equipment .equipments-add-equipment-body > .MuiGrid-root {
  padding: 1em; }
  .main-content-wrapper-mobile .equipments-add-equipment .equipments-add-equipment-body > .MuiGrid-root > .MuiGrid-item.fob-user-details-container {
    min-height: 0;
    padding-right: 2em;
    padding-left: 2em; }
  .main-content-wrapper-mobile .equipments-add-equipment .equipments-add-equipment-body > .MuiGrid-root > .MuiGrid-item.equipments-add-equipment-warranty-service-section {
    padding-left: 2rem;
    padding-right: 2rem; }

.equipments-add-equipment-form {
  padding: 0 3em; }
  .equipments-add-equipment-form .fob-input-btn-form-control label {
    font-size: 0.8em;
    padding: 0 0 1em 0; }
  .equipments-add-equipment-form .fob-input-btn-form-control button {
    min-width: 0;
    max-width: 200px; }
  .equipments-add-equipment-form .fob-date-picker {
    max-width: 100%; }

/* Mobile Styles */
.main-content-wrapper-mobile .equipments-add-equipment-form {
  padding: 0 1em; }

.equipments-add-address-dialog .two-input-row-grid > .MuiGrid-item:first-of-type {
  padding-left: 0;
  padding-right: 10px; }

.equipments-add-address-dialog .two-input-row-grid > .MuiGrid-item:last-of-type {
  padding-right: 0;
  padding-left: 10px; }

/* Mobile styles */
.MuiDialog-root-mobile.equipments-add-address-dialog .two-input-row-grid > .MuiGrid-item:first-of-type {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 16px; }

.MuiDialog-root-mobile.equipments-add-address-dialog .two-input-row-grid > .MuiGrid-item:last-of-type {
  padding-right: 0;
  padding-left: 0; }

.equipments-upload-activity-images {
  background: url(../images/activity.png) bottom center no-repeat;
  background-size: contain !important; }
  .equipments-upload-activity-images .dropzone {
    min-width: 250px;
    min-height: 250px;
    position: relative;
    flex: 1;
    display: flex; }
    .equipments-upload-activity-images .dropzone:focus {
      outline: none; }
    .equipments-upload-activity-images .dropzone .activity-dropzone-label {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      visibility: hidden; }
    .equipments-upload-activity-images .dropzone ul.activity-dropzone-files {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .equipments-upload-activity-images .dropzone ul.activity-dropzone-files li {
        max-height: 100px !important; }
        .equipments-upload-activity-images .dropzone ul.activity-dropzone-files li .MuiGridListTile-tile {
          display: flex;
          flex: 1; }
        .equipments-upload-activity-images .dropzone ul.activity-dropzone-files li img {
          max-width: 80px;
          max-height: 80px;
          height: auto; }
        .equipments-upload-activity-images .dropzone ul.activity-dropzone-files li .activity-dropzone-file-remove {
          position: absolute;
          z-index: 2;
          right: 0;
          top: 0;
          background-color: #E3475E;
          color: white;
          padding: 3px; }
          .equipments-upload-activity-images .dropzone ul.activity-dropzone-files li .activity-dropzone-file-remove .MuiSvgIcon-root {
            font-size: 1rem; }
  .equipments-upload-activity-images:hover .activity-dropzone-label {
    visibility: visible; }

.admin-admin-option-dashboard .fob-content-body {
  padding: 5em 4em 5em 4em; }

.admin-category-btn {
  padding: 1em;
  width: 100%;
  height: 25vh;
  margin: 1em; }
  .admin-category-btn > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .admin-category-btn .MuiPaper-root {
    width: 100%;
    height: 100%;
    background-color: #eeebe9;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .admin-category-btn .MuiPaper-root > div {
      display: flex;
      flex: 1;
      justify-content: center; }
  .admin-category-btn button {
    border-radius: 2em;
    margin: -1.5em 0 0 0;
    text-transform: capitalize; }
  .admin-category-btn img {
    width: auto;
    max-height: 120px; }

.profile-my-profile-form .profile-my-profile-form-container {
  padding: 4em 0 3em 0; }
  .profile-my-profile-form .profile-my-profile-form-container > .MuiGrid-item {
    padding: 2em 3em 1em 3em; }
  .profile-my-profile-form .profile-my-profile-form-container .address-grid-container {
    flex-basis: unset; }
    .profile-my-profile-form .profile-my-profile-form-container .address-grid-container .grid-item {
      flex-basis: unset;
      max-width: 100%; }
  .profile-my-profile-form .profile-my-profile-form-container .company-info-container {
    height: auto;
    padding: 0 0 20px 0;
    min-height: 0; }

.profile-my-profile-form .my-profile-form-item-group .my-profile-form-item-group-header {
  color: #c0b7b1; }
  .profile-my-profile-form .my-profile-form-item-group .my-profile-form-item-group-header .MuiSvgIcon-root {
    padding-right: 8px; }
  .profile-my-profile-form .my-profile-form-item-group .my-profile-form-item-group-header h6 {
    color: #c0b7b1; }

.profile-my-profile-form .my-profile-form-item-group .MuiDivider-root {
  margin: 0.25em 0 1.5em 0; }

.profile-my-profile-form .my-profile-primary-actions-container {
  padding: 0; }
  .profile-my-profile-form .my-profile-primary-actions-container > .MuiGrid-item {
    padding: 0 0 0 2em; }
    .profile-my-profile-form .my-profile-primary-actions-container > .MuiGrid-item button {
      margin: 0 1em;
      width: 160px; }

/* Mobile styles */
.main-content-wrapper-mobile .profile-my-profile-form .profile-my-profile-form-container > .MuiGrid-item {
  padding: 1em 1em 2em 1em; }

.main-content-wrapper-mobile .profile-my-profile-form .profile-my-profile-form-container .company-info-container {
  padding-bottom: 3em; }

.profile-my-profile-form-header {
  width: 100%; }
  .profile-my-profile-form-header > .MuiGrid-root {
    position: relative; }
    .profile-my-profile-form-header > .MuiGrid-root .change-cover-btn {
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 16px;
      right: 16px;
      color: #e6e6e6;
      padding: 0.5em 0.8em;
      border-radius: 16px;
      font-size: 0.75em; }
    .profile-my-profile-form-header > .MuiGrid-root .user-name {
      position: absolute;
      bottom: 8px;
      left: 180px;
      text-transform: uppercase;
      color: white; }
    .profile-my-profile-form-header > .MuiGrid-root .cover-photo {
      height: 180px;
      width: 100%;
      border-radius: 0.5em;
      overflow: hidden; }
      .profile-my-profile-form-header > .MuiGrid-root .cover-photo img {
        width: 100%;
        height: 100%; }
      .profile-my-profile-form-header > .MuiGrid-root .cover-photo .user-bg-input {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0; }
      .profile-my-profile-form-header > .MuiGrid-root .cover-photo .user-bg-edit-btn {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
        opacity: 0;
        transition: opacity 0.2s; }
      .profile-my-profile-form-header > .MuiGrid-root .cover-photo:hover .user-bg-edit-btn {
        opacity: 1; }
      .profile-my-profile-form-header > .MuiGrid-root .cover-photo .cover-loading {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center; }
    .profile-my-profile-form-header > .MuiGrid-root .user-avatar {
      position: absolute;
      bottom: -30px;
      left: 45px;
      height: 85px; }
      .profile-my-profile-form-header > .MuiGrid-root .user-avatar .user-avatar-input {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0; }
      .profile-my-profile-form-header > .MuiGrid-root .user-avatar .user-avatar-img {
        border-radius: 50%;
        width: 85px;
        height: 85px;
        overflow: hidden;
        background-color: #ffffff; }
        .profile-my-profile-form-header > .MuiGrid-root .user-avatar .user-avatar-img .avatar-loading {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 2;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center; }
        .profile-my-profile-form-header > .MuiGrid-root .user-avatar .user-avatar-img .MuiAvatar-root {
          height: 100%;
          width: 100%;
          font-size: 2.5em; }
      .profile-my-profile-form-header > .MuiGrid-root .user-avatar .user-avatar-edit-btn {
        position: absolute;
        right: -1em;
        bottom: -1em;
        opacity: 0;
        transition: opacity 0.2s; }
      .profile-my-profile-form-header > .MuiGrid-root .user-avatar:hover .user-avatar-edit-btn {
        opacity: 1; }

#change-cover-file {
  display: none; }

.help-help-dashboard-content {
  padding: 4em 2em 2em 2em; }
  .help-help-dashboard-content .guides-container {
    padding-top: 3em !important; }

.search-input-container {
  min-width: 50%;
  max-width: 50%; }

.fob-search-input-container {
  width: 100%; }
  .fob-search-input-container .MuiFilledInput-root {
    background-color: white;
    padding: 0.5em;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }
    .fob-search-input-container .MuiFilledInput-root > input {
      padding: 0.5em 0 0.5em 1em;
      color: #3f3833;
      background-color: white; }
    .fob-search-input-container .MuiFilledInput-root:hover {
      background-color: white; }
    .fob-search-input-container .MuiFilledInput-root::after {
      border-bottom: none; }

.support-card-item .MuiPaper-root {
  width: 100%;
  height: 180px; }

.support-card-item .MuiCardContent-root {
  justify-content: center;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%; }

.support-card-item p {
  font-size: 0.75em;
  color: #A99D95; }

.support-card-item .MuiSvgIcon-root {
  color: #E3475E;
  font-size: 4em; }

/* Mobile styles */
.main-content-wrapper-mobile .support-card-item .MuiPaper-root {
  height: auto; }

.help-user-guide-card {
  background-color: #f9f8f8 !important; }
  .help-user-guide-card .MuiCardHeader-subheader {
    color: #E3475E;
    text-transform: uppercase;
    font-weight: 500; }
  .help-user-guide-card .MuiCardContent-root {
    padding-top: 0; }
    .help-user-guide-card .MuiCardContent-root .MuiListItemText-primary {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.search-search .header-actions {
  margin: 12px 0;
  min-width: 200px !important; }

.search-search-result-table .manufacturer-search-select {
  min-width: 150px; }

.search-search-result-table .search-input-form {
  width: 100%; }

.search-input-ext .MuiInputBase-root {
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
  .search-input-ext .MuiInputBase-root input {
    padding-left: 8px; }

/* Mobile styles */
.main-content-wrapper-mobile .search-input-form {
  padding-top: 1.5em; }

.manufacturers-manufacturers-table .datatable-add-item-btn {
  margin: 0 0 0 60px; }

.manufacturers-manufacturer-details-dialog > .MuiDialog-container > .MuiDialog-paper {
  max-width: 700px;
  width: 100%; }

.manufacturers-manufacturer-details-dialog > .MuiDialog-container .MuiDialogContent-root {
  overflow: hidden; }

.manufacturers-manufacturers-information-dialog .MuiDialogContent-root {
  padding-top: 0; }
  .manufacturers-manufacturers-information-dialog .MuiDialogContent-root .MuiTableContainer-root {
    width: inherit; }
    .manufacturers-manufacturers-information-dialog .MuiDialogContent-root .MuiTableContainer-root .MuiTable-root {
      table-layout: fixed;
      width: 100%; }
  .manufacturers-manufacturers-information-dialog .MuiDialogContent-root table tbody .tr-two-col-cell td:first-of-type {
    text-align: right; }
  .manufacturers-manufacturers-information-dialog .MuiDialogContent-root table tbody td {
    padding: 8px 6px 8px 3px;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .manufacturers-manufacturers-information-dialog .MuiDialogContent-root table tbody td a {
      text-decoration: none; }
  .manufacturers-manufacturers-information-dialog .MuiDialogContent-root table tbody .td-single-row-val {
    padding: 0 6px 8px 3px; }
  .manufacturers-manufacturers-information-dialog .MuiDialogContent-root img.manufacturers-info-img {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none;
    max-width: 250px;
    max-height: 250px; }

.manufacturers-manufacturers-information-dialog .MuiDialogActions-root {
  padding: 16px 8px 20px 8px; }
  .manufacturers-manufacturers-information-dialog .MuiDialogActions-root button {
    min-width: 150px;
    margin: 0 auto; }

.parts-part-details-dialog > .MuiDialog-container > .MuiDialog-paper {
  max-width: 700px;
  width: 100%; }

.parts-part-details-dialog > .MuiDialog-container .MuiDialogContent-root {
  overflow: hidden; }

.parts-part-details-dialog > .MuiDialog-container .MuiDialogActions-root {
  justify-content: center;
  padding: 2em 0 2.5em 0; }
  .parts-part-details-dialog > .MuiDialog-container .MuiDialogActions-root .MuiButton-root {
    width: 70%;
    max-width: 180px;
    margin: 0 0.75em; }

.roles-role-details-dialog > .MuiDialog-container > .MuiDialog-paper {
  max-width: 700px;
  width: 100%; }

.roles-role-details-dialog > .MuiDialog-container .MuiDialogContent-root {
  overflow: hidden; }

.roles-role-details-dialog > .MuiDialog-container .MuiDialogActions-root {
  justify-content: center;
  padding: 2em 0 2.5em 0; }
  .roles-role-details-dialog > .MuiDialog-container .MuiDialogActions-root .MuiButton-root {
    width: 70%;
    max-width: 180px;
    margin: 0 0.75em; }

.dashboard-dashboard .dashboard-dashboard-body {
  padding: 1rem; }

.paper-select .MuiSelect-root {
  padding: 8px 24px 8px 12px !important; }

.paper-select .MuiInput-underline:before, .paper-select .MuiInput-underline:after {
  transition: none !important;
  border-bottom: none !important; }

.dashboard-dashboard-content .dashboard-dashboard-content-header-title {
  text-transform: uppercase;
  font-weight: 500;
  padding: 1rem 0; }

.warranty-legend {
  min-width: 230px;
  padding: 12px; }
  .warranty-legend ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0; }
    .warranty-legend ul li {
      padding: 4px 0;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.65); }
      .warranty-legend ul li .warranty-legend-color {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin: 0 8px 0 0; }

.dashboard-chart-2d-doughnut-paper {
  width: 100%;
  background-color: #fafbfc;
  min-height: 250px; }
  .dashboard-chart-2d-doughnut-paper .dashboard-chart-2d-doughnut-paper-grid {
    padding: 1rem 0.5rem; }
    .dashboard-chart-2d-doughnut-paper .dashboard-chart-2d-doughnut-paper-grid > .MuiGrid-item {
      padding: 0.5rem; }
  .dashboard-chart-2d-doughnut-paper .dashboard-chart-2d-doughnut-paper-toggle-frequence-container {
    padding: 1rem; }
  .dashboard-chart-2d-doughnut-paper .dashboard-chart-2d-doughnut-paper-bottom-title {
    color: #E3475E;
    padding: 0 0 1rem; }
  .dashboard-chart-2d-doughnut-paper .dashboard-chart-2d-doughnut-paper-filter-container {
    display: flex;
    flex: 1; }
    .dashboard-chart-2d-doughnut-paper .dashboard-chart-2d-doughnut-paper-filter-container .MuiIconButton-root {
      padding: 6px 12px; }
  .dashboard-chart-2d-doughnut-paper .DateRangePicker .DateRangePickerInput {
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
    .dashboard-chart-2d-doughnut-paper .DateRangePicker .DateRangePickerInput .DateInput_input {
      padding: 8px 0; }
    .dashboard-chart-2d-doughnut-paper .DateRangePicker .DateRangePickerInput .DateInput__small {
      max-width: 70px; }
  .dashboard-chart-2d-doughnut-paper .DateRangePickerInput_calendarIcon {
    padding: 0; }
